<template>
    <div class="positionT0L0">
        <div class="app-form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="productType" label="商品类型" prop="productType">
                    <a-radio-group v-model="form.productType" name="productType">
                        <a-radio :value="2">会员</a-radio>
                        <a-radio :value="1">课程</a-radio>
                    </a-radio-group>
                </a-form-model-item>

                <a-form-model-item ref="productName" label="直销商品名称" prop="productName">
                    <a-input v-model="form.productName" placeholder="请输入直销商品名称">
                        <div slot="addonAfter">{{ form.productName.length }}/30</div>
                    </a-input>
                </a-form-model-item>

                <a-form-model-item label="商品关联" class="product" :class="{ 'has-error': isCheckCouseNo }" required>
                    <div class="product-box" v-if="mdl">
                        <a-icon v-if="this.$route.params.id == 0" type="close-circle" :style="{ fontSize: '20px', color: '#9FA5AF' }" class="product-close" @click="closeHandle"/>
                        <div class="product-wrap flexbox justify middle" :class="{ bg: this.$route.params.id != 0 }">
                            <div class="left-wrap flex1 flexbox left">
<!--                                <div class="product-img">-->
<!--                                    <img class="p-img" v-if="mdl.coverUrl" :src="mdl.coverUrl" alt=""/>-->
<!--                                    <img class="p-img" v-if="mdl.coverImg" :src="mdl.coverImg" alt=""/>-->
<!--                                </div>-->
                                <div class="product-info flex1">
                                    <div style="height: 56px" v-if="false">
                                        <div class="p-title more-t">{{ form.businessName }}</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 0" class="p-price">免费</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 1" class="p-price">￥{{ mdl.price }}</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 2" class="p-price">不支持单独售卖</div>
                                        <div v-show="form.businessType == 2" class="p-price">￥{{ mdl.price }}</div>
                                    </div>
                                    <div>
                                        <div class="p-type">商品类型：{{ form.businessType == 1 ? "课程" : "会员" }}</div>
                                        <div class="p-type">会期单位：{{ form.businessType == 2 ? form.validDays : '永久' }}</div>
                                        <div class="p-type">商品原价：{{ mdl.price }}</div>
                                        <div class="p-type">商品描述：{{ form.businessName }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-wrap">
                                <a-button type="primary" @click="addProduct">更换商品</a-button>
                            </div>
                        </div>
                    </div>
                    <a-button v-else type="primary" @click="addProduct">添加商品</a-button>
                    <div class="ant-form-explain" v-if="isCheckCouseNo">请添加商品</div>
                </a-form-model-item>

                <a-form-model-item ref="productPrice" label="商品价格" prop="productPrice">
                    <a-input-number v-model="form.productPrice" placeholder="请输入商品价格" style="width:100%"/>
                </a-form-model-item>

                <a-form-model-item ref="useType" label="商品用途" prop="useType">
                    <a-select v-model="form.useType" placeholder="请选择商品用途" style="width:100%">
                        <a-select-option :value="1">售卖</a-select-option>
                        <a-select-option :value="2">赠送</a-select-option>
                    </a-select>
                    <div style="line-height: 20px;color: #f59a23">售卖：用户开通付费/使用后计入销售业绩；<br>赠送：用户开通付费/使用后销售业绩单独标记；</div>
                </a-form-model-item>

                <a-form-model-item ref="changeAgent" label="用户归属限制" prop="changeAgent">
                    <a-select v-model="form.changeAgent" placeholder="请选择用户归属限制" style="width: 200px">
                        <a-select-option :value="1">更改</a-select-option>
                        <a-select-option :value="0">不更改</a-select-option>
                    </a-select>
                    <div style="line-height: 20px;color: #f59a23">改变归属：用户使用后，用户归属变为直销的对应销售渠道 <br>不改变归属：用户使用后，用户归属不会变为直销的对应销售渠道</div>
                </a-form-model-item>

                <a-form-model-item ref="useCondition" label="用户使用限制" prop="useCondition">
                    <a-select v-model="form.useCondition" placeholder="请选择用户使用限制" style="width:100%">
                        <a-select-option :value="1">无限制</a-select-option>
                        <a-select-option :value="2">无会期记录用户</a-select-option>
                        <a-select-option :value="3">无课程正式权益记录用户可用</a-select-option>
                    </a-select>
                    <div style="line-height: 20px;color: #f59a23">无限制：所有用户都能用 <br>无会期记录用户可用：要求使用的用户无会期的使用记录 <br>无课程正式权益记录用户可用：要求使用的用户无课程正式权益记录的使用记录</div>
                </a-form-model-item>

                <a-form-model-item ref="useAgain" label="是否重复使用" prop="useAgain">
                    <a-radio-group v-model="form.useAgain" name="useAgain">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                    <div style="line-height: 20px;color: #f59a23">是：一个用户可以使用多次；<br>否：一个用户只能使用一次</div>
                </a-form-model-item>

                <a-form-model-item ref="rightsType" label="权益类型" prop="rightsType">
                    <a-select v-model="form.rightsType" placeholder="请选择权益类型" style="width: 200px">
                        <a-select-option :value="1">正式</a-select-option>
                        <a-select-option :value="2">体验</a-select-option>
                    </a-select>
                    <div style="line-height: 20px;color: #f59a23">正式：用户获得正式权益<br>体验：用户获得体验权益</div>
                </a-form-model-item>

                <a-form-model-item ref="agentPlatform" label="渠道销售类型" prop="agentPlatform">
                    <a-select v-model="form.agentPlatform" placeholder="请选择渠道销售类型" style="width: 200px">
                        <a-select-option :value="1">第三方平台</a-select-option>
                        <a-select-option :value="2">代理商</a-select-option>
                        <a-select-option :value="3">总部直销</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item ref="descb" label="实体卡商品说明" prop="descb">
                    <a-textarea v-model="form.descb" :rows="4" placeholder="请输入实体卡商品说明" />
                </a-form-model-item>

                <a-form-model-item ref="enableFlag" label="是否可用" prop="enableFlag">
                    <a-radio-group v-model="form.enableFlag" name="enableFlag">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                    <div style="line-height: 20px;color: #f59a23">是：上架状态<br>否：下架状态</div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <RadioModal
            v-if="visible"
            :modalVisible="visible"
            :tabType="form.businessType"
            :type="0"
            :isShowMember="true"
            :selectedRowKeys="selectedRowKeys"
            @cancel="handleCancel"
            @ok="handleOk"
        />
        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="backClick">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import RadioModal from "@/components/courseMember/newRadioModal";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import {ProductDetail,AddProduct,EditProduct} from "@/request/api/physicalCard";

export default {
    mixins: [baseMixin],
    components: {
        RadioModal,
        FooterToolBar,
    },
    data() {
        return {
            type:0,
            flang:false,
            isDisabled: false, // 保存的按钮是否禁用
            labelCol: { span: 3 },
            wrapperCol: { span: 8 },
            form: {
                id: this.$route.params.id != 0 ? this.$route.params.id : "",
                productType:2,
                productName:'',
                productPrice:'',
                useType:undefined,
                changeAgent:undefined,
                useAgain:1,
                useCondition:undefined,
                rightsType:undefined,
                agentPlatform:undefined,
                descb:'',
                enableFlag:1,
                // 课程属性
                businessType:1,
                courseNo:'',
                businessNo:'',
                businessName:'',
                validDays:0,
            },
            rules: {
                productType: [{ required: true, message: "请选择商品类型", trigger: "change" }],
                productName: [
                    { required: true, message: "请输入直销商品名称", trigger: "blur" },
                    { max: 45, message: "最多输入30个字符", trigger: "change" },
                ],
                productPrice: [{ required: true, message: "请输入商品价格", trigger: "blur" }],
                useType: [{ required: true, message: "请选择商品用途", trigger: "change" }],
                changeAgent: [{ required: true, message: "请选择用户归属限制", trigger: "change" }],
                useAgain: [{ required: true, message: " 请选择", trigger: "change" }],
                useCondition: [{ required: true, message: " 请选择用户使用限制", trigger: "change" }],
                rightsType: [{ required: true, message: " 请选择权益类型", trigger: "change" }],
                agentPlatform: [{ required: true, message: " 请选择渠道销售类型", trigger: "change" }],
                // descb: [{ required: true, message: " 请输入实体卡商品说明", trigger: "blur" }],
                enableFlag: [{ required: true, message: " 请选择", trigger: "change" }],
            },
            isCheckCouseNo: false, // 是否添加商品
            visible: false,
            mdl: null,
            selectedRowKeys: [],
        };
    },
    watch: {
        mdl(newVal) {
            if (newVal == null || newVal == "") {
                this.isCheckCouseNo = true;
            } else {
                this.isCheckCouseNo = false;
            }
        },
    },
    created() {
        this.type = this.$route.params.type
        if (this.type == 1 || this.type == 2) {
            this.CodeDetailApi();
        }
    },
    methods: {
        // 详情
        CodeDetailApi() {
            ProductDetail({
                id: this.$route.params.id
            }).then(({ code, data }) => {
                let formData = data;
                this.mdl = formData.businessDetail
                this.mdl.courseNo = formData.businessDetail.businessNo
                this.form = formData.productDetail
                this.form.businessName = formData.businessDetail.describtion
                this.form.businessType = formData.businessDetail.businessType
                this.form.businessNo = formData.productDetail.businessNo
                this.form.validDays = formData.businessDetail.validDays
            });
        },

        // 添加商品事件
        addProduct(businessType) {
            if (!this.mdl) {
                this.mdl = null;
            }
            this.visible = true;
        },

        // 弹窗中取消按钮事件
        handleCancel() {
            this.visible = false;
        },

        // 弹窗中确认按钮事件
        handleOk(selectedRowKeys, selectedRows, businessType) {
            this.flang = true
            if (businessType == 1 && selectedRows.courseNo) {
                this.mdl = selectedRows;
                this.form.businessType = 1
                this.form.productType = 1
                this.selectedRowKeys = selectedRowKeys;
                this.form.courseNo = selectedRows.courseNo;
                this.form.businessNo = selectedRows.courseNo;
                this.form.businessName = selectedRows.name;
            }else if (businessType == 2 && selectedRows.memberNo) {
                // 会员
                this.mdl = selectedRows;
                this.form.businessType = 2
                this.form.productType = 2
                this.selectedRowKeys = selectedRowKeys;
                this.form.businessNo = selectedRows.memberNo;
                this.form.businessName = selectedRows.memberName;
                this.form.validDays = selectedRows.days;
            }
            this.visible = false;
        },

        // 清除商品信息
        closeHandle() {
            this.mdl = null;
            this.form.businessType = 1;
            this.form.businessNo = "";
            this.form.courseNo = "";
            this.form.businessName = "";
            this.selectedRowKeys = [];
        },
        // 返回上一页
        backClick() {
            this.$router.push("/physicalCard/physicalCardList");
        },
        keepClick() {
            const that = this;
            console.log(that.form)
            that.isDisabled = true;
            setTimeout(() => {
                that.isDisabled = false;
            }, 3000);
            // 自定义验证添加商品规则
            if (that.mdl == null || that.mdl == "") {
                that.isCheckCouseNo = true;
            } else {
                that.isCheckCouseNo = false;
            }
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.type == 0 || this.type == 2) {
                        AddProduct(that.form).then((res) => {
                            if (res.code == 200) {
                                that.$message.success("新增成功");
                                that.$router.push("/physicalCard/physicalCardList");
                            }
                        });
                    } else {
                        EditProduct(that.form).then((res) => {
                            if (res.code == 200) {
                                that.$message.success("编辑成功");
                                that.$router.push("/physicalCard/physicalCardList");
                            }
                        });
                    }
                }
            });
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem("formData");
    },
};
</script>

<style lang="less" scoped>
.date-picker-label {
    margin-left: 10px;
    margin-right: 10px;
}
.product-box {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        &.bg {
            background: #ebebeb;
        }
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                padding-left:15px ;
                //height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
/deep/.product .ant-form-item-control {
    line-height: normal;
}
/deep/.edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
    width: 100%;
}
/deep/.date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
</style>
