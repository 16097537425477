<template>
    <div class="positionT0L0">
        <div class="app-form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="name" label="话题名称" prop="name">
                    <a-input v-model="form.name" placeholder="请输入话题名称">
                        <div slot="addonAfter">{{ form.name.length }}/45</div>
                    </a-input>
                </a-form-model-item>
                <a-form-model-item ref="status" label="上架设置" prop="status">
                    <a-select v-model="form.status" placeholder="请选择上架设置" style="width: 200px">
                        <a-select-option v-for="item in typeList" :key="item.key">{{item.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item ref="topicOrder" label="排序" prop="topicOrder">
                    <a-input-number v-model="form.topicOrder" placeholder="请输入排序" style="width:100%"/>
                </a-form-model-item>

                <a-form-model-item label="对应内容" class="product" :class="{ 'has-error': isCheckCouseNo }" required>
                    <div class="product-box" v-if="mdl">
                        <a-icon v-if="this.$route.params.id == 0" type="close-circle" :style="{ fontSize: '20px', color: '#9FA5AF' }" class="product-close" @click="closeHandle"/>
                        <div class="product-wrap flexbox justify middle" :class="{ bg: this.$route.params.topicNo != 0 }">
                            <div class="left-wrap flex1 flexbox left">
                                <div class="product-img">
                                    <img class="p-img" v-if="mdl.coverUrl" :src="mdl.coverUrl" alt=""/>
                                    <img class="p-img" v-if="mdl.coverImg" :src="mdl.coverImg" alt=""/>
                                </div>
                                <div class="product-info flex1">
                                    <div style="height: 56px">
                                        <div class="p-title more-t">{{ form.businessName }}</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 0" class="p-price">免费</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 1" class="p-price">￥{{ mdl.price }}</div>
                                        <div v-show="form.businessType == 1 && mdl.pricingType == 2" class="p-price">不支持单独售卖</div>
                                        <div v-show="form.businessType == 2" class="p-price">￥{{ mdl.price }}</div>
                                    </div>
                                    <div class="p-type">商品类型：{{ form.businessType == 1 ? "课程" : "会员" }}</div>
                                </div>
                            </div>
                            <div class="right-wrap">
                                <a-button type="primary" @click="addProduct">更换商品</a-button>
                            </div>
                        </div>
                    </div>
                    <a-button v-else type="primary" @click="addProduct">添加商品</a-button>
                    <div class="ant-form-explain" v-if="isCheckCouseNo">请添加商品</div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <RadioModal
            v-if="visible"
            :modalVisible="visible"
            :tabType="form.businessType"
            :type="0"
            :isShowMember="false"
            :selectedRowKeys="selectedRowKeys"
            @cancel="handleCancel"
            @ok="handleOk"
        />
        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="backClick">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import RadioModal from "@/components/courseMember/RadioModal";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import {AddTopicCategory,GetTopicCategoryDetail} from "@/request/api/circleManage";

export default {
    mixins: [baseMixin],
    components: {
        RadioModal,
        FooterToolBar,
    },
    data() {
        return {
            typeList: [
                { key: 1, label: "立即上架" },
                { key: 0, label: "暂不上架" },
            ],
            isDisabled: false, // 保存的按钮是否禁用
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            labelCol: { span: 2 },
            wrapperCol: { span: 10 },
            form: {
                topicNo: this.$route.params.topicNo != 0 ? this.$route.params.topicNo : "",
                name: "",
                courseNo: '',
                topicOrder:null,
                status: undefined,
                businessType:1,
                businessName:'',
            },
            rules: {
                name: [
                    { required: true, message: "请输入话题名称", trigger: "blur" },
                    { max: 45, message: "最多输入45个字符", trigger: "change" },
                ],
                status: [{ required: true, message: "请选择类型", trigger: "change" }],
                topicOrder: [{ required: true, message: "请输入排序", trigger: "blur" }],
            },
            isCheckCouseNo: false, // 是否添加商品
            visible: false,
            mdl: null,
            selectedRowKeys: [],
        };
    },
    watch: {
        mdl(newVal) {
            if (newVal == null || newVal == "") {
                this.isCheckCouseNo = true;
            } else {
                this.isCheckCouseNo = false;
            }
        },
    },
    created() {
        if (this.$route.params.topicNo != 0) {
            this.CodeDetailApi();
        }
    },
    methods: {
        onOk(){

        },


        // 详情
        CodeDetailApi() {
            let id = this.$route.params.topicNo;
            GetTopicCategoryDetail({
                topicNo: id,
            }).then(({ code, data }) => {
                let formData = data;
                this.mdl = formData;
                this.form.name = formData.name;
                this.form.status = formData.status;
                this.form.topicOrder = formData.topicOrder;
                this.form.businessType = formData.businessType
                this.form.courseNo = formData.businessNo
                this.form.businessName = formData.businessName
            });
        },

        // 添加商品事件
        addProduct(businessType) {
            if (!this.mdl) {
                this.mdl = null;
            }
            this.visible = true;
        },

        // 弹窗中取消按钮事件
        handleCancel() {
            this.visible = false;
        },

        // 弹窗中确认按钮事件
        handleOk(selectedRowKeys, selectedRows, businessType) {
            if (businessType == 1 && selectedRows.courseNo) {
                this.mdl = selectedRows;
                this.selectedRowKeys = selectedRowKeys;
                this.form.courseNo = selectedRows.courseNo;
                this.form.businessName = selectedRows.name;
            }
            this.visible = false;
        },

        // 清除商品信息
        closeHandle() {
            this.mdl = null;
            this.form.businessType = 1;
            this.form.businessNo = "";
            this.form.businessName = "";
            this.selectedRowKeys = [];
        },
        // 返回上一页
        backClick() {
            this.$router.push("/circleManage/talkList");
        },
        keepClick() {
            const that = this;
            that.isDisabled = true;
            setTimeout(() => {
                that.isDisabled = false;
            }, 3000);
            // 自定义验证添加商品规则
            if (that.mdl == null || that.mdl == "") {
                that.isCheckCouseNo = true;
            } else {
                that.isCheckCouseNo = false;
            }
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.$route.params.topicNo == 0) {
                        AddTopicCategory(that.form).then((res) => {
                            if (res.code == 200) {
                                that.$message.success("新增成功");
                                that.$router.push("/circleManage/talkList");
                            }
                        });
                    } else {
                        AddTopicCategory(that.form).then((res) => {
                            if (res.code == 200) {
                                that.$message.success("编辑成功");
                                that.$router.push("/circleManage/talkList");
                            }
                        });
                    }
                }
            });
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem("formData");
    },
};
</script>

<style lang="less" scoped>
.date-picker-label {
    margin-left: 10px;
    margin-right: 10px;
}
.product-box {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        &.bg {
            background: #ebebeb;
        }
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
/deep/.product .ant-form-item-control {
    line-height: normal;
}
/deep/.edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
    width: 100%;
}
/deep/.date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
</style>
